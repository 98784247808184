//
//
//
//
//
//
//
//
//
//
//
//

import Navigation from '~/components/layout/Navigation'
import NavigationSideBar from '~/components/layout/SideNav'
import Cart from '~/components/layout/Cart'
import CartIco from '~/components/layout/CartIco'
import ConfirmOrder from '~/components/layout/ConfirmOrder'
import Footer from '~/components/layout/Footer'


const CRONITOR_ID = process.env.CRONITOR_ROMZA;
console.log('CRONITOR_ID = = '+CRONITOR_ID);

export default {
	components: {
		Navigation, NavigationSideBar, Cart, ConfirmOrder, CartIco, Footer
	},

	watch: {
        '$route': function() {
					// Panelbear.trackPageview();
        	if(this.$store.getters['nav/toggleSidebar']){
                this.$store.dispatch('nav/toggleSidebar')
                document.querySelector('body').style.removeProperty('overflow')
            }
        }
    },
	data() {
		return {
			CRONITOR_ID: this.$config.CRONITOR
		};
	},
	head () {
		const metaArray = [];
		var metatitle = '';
		if(this.$store.state.post && this.$store.state.post.yoast_meta){
			this.$store.state.post.yoast_meta.map(ele => {
				if(ele.name!='robots'){
					var my_content = ele.content
					if(ele.property!='og:image')
						my_content = ele.content.replace('-admin', '')
					metaArray.push({
						hid: ele.name ? ele.name : ele.property,
						name: ele.name ? ele.name : ele.property,
						content: my_content,
					});
				}
			});
			metatitle = this.$store.state.post.yoast_meta[4].content
		}
		return {
			title: metatitle ,
			meta: metaArray,
		}
	},

	mounted() {
		window.cronitor = window.cronitor || function() { (window.cronitor.q = window.cronitor.q || []).push(arguments); };
		cronitor('config', { clientKey: CRONITOR_ID });
		console.log('cronitor monted');
	},

}
